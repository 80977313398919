<template>
    <div class="map-page map-page-list map-page-users-list" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <div class="map-admin-list">
                <header class="map-admin-list-header">
                    <h1 v-on:click="user_types_expanded = !user_types_expanded">List of {{ this.tabs[current_user_type] }}</h1>
                    <ul
                        class="map-types-list"
                        :class="{ 'map-expanded': user_types_expanded }"
                        >
                        <li
                            v-for="(type, key) in tabs"
                            :key="key"
                            :class="{ 'map-selected': key === current_user_type }"
                            v-on:click="change_user_type(key)"
                            >
                            {{ type }}
                        </li>
                    </ul>
                    <router-link class="map-button map-color-primary" :to="{ name: 'UsersCreate' }" v-if="logged_user.role === 'super-admin'">CREATE NEW USER</router-link>
                    <button class="map-button map-color-primary map-download-csv" @click="export_users">Download XLSX</button>
                    <panel
                        class="map-search-panel"
                        :dark="true"
                        >
                        <base-input
                            :round="true"
                            v-model="search_term"
                            placeholder="Search users..."
                            @change="filter_users"
                            >
                        </base-input>
                        <Button class="map-search-button" icon="icon-search" color="primary" @click="filter_users">
                            Search
                        </Button>
                    </panel>
                </header>
                <div
                    class="map-table map-users-table"
                    :class="{
                        'map-loading': loading,
                        'map-users-pending': current_user_type === 'pending',
                    }"
                    >
                    <header>
                        <div class="map-table-col">#</div>
                        <div class="map-table-col">Name</div>
                        <div class="map-table-col">Email</div>
                        <div class="map-table-col">Organization</div>
                        <div class="map-table-col" v-if="current_user_type === 'pending'">Who shared the link</div>
                        <!-- <div class="map-table-col">Centers</div> -->
                        <div class="map-table-col">Role</div>
                        <div class="map-table-col">Referral</div>
                        <div class="map-table-col" v-if="logged_user.role === 'super-admin'">Actions</div>
                    </header>
                    <div class="map-table-body">
                        <loader />
                        <p class="map-table-empty" v-if="!has_users && !loading">There are no items to display.</p>
                        <div class="map-table-row" v-for="(user, key) in filtered_users" :key="key">
                            <div class="map-table-col">{{ key + 1 }}</div>
                            <div class="map-table-col">{{ user.name }} {{ user.surname }}</div>
                            <div class="map-table-col">{{ user.email }}</div>
                            <div class="map-table-col">{{ user.organization }}</div>
                            <div class="map-table-col" v-if="current_user_type === 'pending'">{{ user.recommended_by }}</div>
                            <!-- <div class="map-table-col">{{ user.centers.join(',') }}</div> -->
                            <div class="map-table-col">{{ user.role }}</div>
                            <div class="map-table-col map-col-referral">
                                <p class="map-recommended-summary">{{ user.recommended_by || '' }}</p>
                                <p class="map-recommended-tooltip" v-if="user.recommended_by">{{ user.recommended_by || '' }}</p>
                            </div>
                            <div class="map-table-col map-no-bg" v-if="logged_user.role === 'super-admin'">
                                <router-link
                                    class="map-button map-color-primary map-button-edit"
                                    :to="{ name: 'UsersEdit', params: { id: user.id } }"
                                    ></router-link>
                                <button
                                     v-if="current_user_type === 'pending'"
                                    class="map-button map-color-primary map-button-approve"
                                    @click="approve_user(user, key)"
                                    >&check;
                                </button>
                                <button
                                    class="map-button map-color-primary map-button-remove"
                                    @click="remove_user(user, key)"
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Loader from '@/components/Loader';

    import { UserService } from '@/services';

    export default
    {
        name: 'UsersList',
        components:
        {
            Button,
            Panel,
            BaseInput,
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_users: false,
                user_types_expanded: false,
                users: [],
                search_term: '',
                current_user_type: 'registered',
                tabs:
                {
                    registered: 'Registered Users',
                    pending: 'Pending Users',
                },
                filtered_users: [],
            };
        },
        computed:
        {
            logged_user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            await this.get_users();
        },
        methods:
        {
            filter_users()
            {
                this.filtered_users = this.users.filter((user) =>
                {
                    return JSON.stringify(user).toLowerCase().includes(this.search_term.toLowerCase());
                });

                this.has_users = this.filtered_users && this.filtered_users.length;
            },

            async get_users()
            {
                const params = {
                    'per-page': -1,
                };

                if (this.current_user_type === 'pending')
                {
                    params['status'] = 'NEW';
                }

                this.loading = true;

                this.users = (await UserService.all(params)).data.data;

                if (this.users && this.users.length)
                {
                    this.has_users = true;
                }

                this.filtered_users = this.users;

                this.loading = false;
            },

            async change_user_type(type)
            {
                this.current_user_type = type;
                this.user_types_expanded = false;

                await this.get_users();
            },

            async remove_user(user, index)
            {
                if (confirm('Are you sure you want to remove this user?'))
                {
                    await UserService.remove(user.id);

                    this.users = this.users.splice(index, 1);
                }
            },

            async approve_user(user, index)
            {
                if (confirm('Are you sure you want to approve this user?'))
                {
                    await UserService.approve(user.id);

                    this.users = this.users.splice(index, 1);
                }
            },

            export_users()
            {
                window.open(process.env.VUE_APP_BACKEND_URL + 'users/export?type=' + this.current_user_type, '_blank');
            }
        },
    }
</script>

<style lang="less" scoped>
.map-recommended-summary
{
    display: block;
    width: 330px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.map-recommended-tooltip
{
    position: absolute;
    top: 100%;
    right: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 50rem;
    border-radius: 1rem;
    border: 1px solid var(--primary-color);
    background-color: #fff;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.12);

    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);

    transition-property: transform, visibility, opacity;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
}

.map-col-referral
{
    position: relative;

    &:hover .map-recommended-tooltip
    {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

.map-share-popup
    {
        position: absolute;
        top: 100%;
        right: 50%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        width: 50rem;
        border-radius: 1rem;
        border: 1px solid var(--primary-color);
        background-color: #fff;
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.12);

        ul
        {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 2rem;
            display: flex;
            width: 100%;
            gap: 1rem;
            font-size: 1.8rem;

            li
            {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: flex-start;
                width: 100%;
                background-color: #fefbfd;
                cursor: pointer;
                border-radius: 1rem;

                .map-input
                {
                    margin-bottom: 0;
                }

                &:hover,
                &.map-expanded
                {
                    background-color: #eff7ff;
                }
            }
        }

        .map-share-actions
        {
            display: flex;
            gap: 1rem;
        }

        .map-button
        {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            width: auto !important;
        }

    }

</style>